import { NgModule } from '@angular/core';
import { WORKFLOW_SERVICE_MAPPING, WORKFLOW_VARIABLE_MAPPING } from '@alf-nx-workspace/shared/utils';
import { LesebestaetigungAnfordernService } from './lesebestaetigung-anfordern.service';
import { LesebestaetigungAnfordernVariablesSearchable } from './lesebestaetigung-anfordern.variables';

@NgModule({
  providers: [
    { provide: WORKFLOW_VARIABLE_MAPPING, useValue: LesebestaetigungAnfordernVariablesSearchable, multi: true },
    { provide: WORKFLOW_SERVICE_MAPPING, useValue: LesebestaetigungAnfordernService, multi: true },
  ],
})
export class LesebestaetigungAnfordernModule {}
