import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { BaseInformationTemplateComponent } from '../../base-information-template.component';
import { InformationElementComponent } from '@alf-nx-workspace/shared/ui';
import { InformationElement } from '@alf-nx-workspace/shared/interfaces';
import { LesebestaetigungAnfordernVariables } from '../lesebestaetigung-anfordern.variables';

@Component({
  selector: 'lesebestaetigung-anfordern-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent],
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public readonly fields = LesebestaetigungAnfordernVariables;

  public getInformationElementProcessEnded(): InformationElement {
    return {name: 'PROCESS.ENDED', value: this.process.ended.toString()};
  }

  public getDocumentToReadFilename(): InformationElement {
    return {name: 'LESEBESTAETIGUNG-ANFORDERN.DOCUMENT', value: this.variableMap.get(this.fields.documentToReadFilename)};
  }
}
