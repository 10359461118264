import { Component, inject } from '@angular/core';
import { BaseResultTemplateComponent } from '../../base-result-template.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RxState } from '@rx-angular/state';
import { ActivitiContentService } from '@alfresco/adf-core';
import { MatButtonModule } from '@angular/material/button';
import { saveAs } from 'file-saver';
import { fromPromise } from 'rxjs/internal-compatibility';
import { LesebestaetigungAnfordernVariables } from '../lesebestaetigung-anfordern.variables';

@Component({
  selector: 'lesebestaetigung-anfordern-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule],
  providers: [RxState],
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {
  readonly activitiContentService = inject(ActivitiContentService);
  readonly fields = LesebestaetigungAnfordernVariables;

  constructor() {
    super();
  }

  ngOnInit() {}

  public downloadContent($event: MouseEvent) {
    $event.preventDefault();

    const confirmationSummaryPdfId: string = this.variableMap.get(this.fields.confirmationSummaryPdf);
    fromPromise(this.activitiContentService.contentApi.getRawContent(parseInt(confirmationSummaryPdfId))).subscribe(
      (content) => {
        saveAs(content, 'Lesebestätigungsliste_'+this.variableMap.get(this.fields.documentToReadFilename));
      }
    );
  }
}
