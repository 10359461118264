import { FormFieldModel, FormFieldValidator } from '@alfresco/adf-core';

export class DokumentenVerteilungValidator implements FormFieldValidator {
  isSupported(field: FormFieldModel): boolean {
    return field.id === 'documentDistribution';
  }

  validate(field: FormFieldModel): boolean {
    if (this.isSupported(field) && field.value != null) {
      const value = field.value;
      const hasOnlyOrganisationsRolle = value.some((element: unknown) =>
        Object.keys(element).length === 1 &&
        element["DOKUMENTEN-VERTEILUNG.VERTEILUNG.MATRIX.ORGANISATIONSROLLE"]
      );
      if(hasOnlyOrganisationsRolle) {
        field.validationSummary.message = 'DOKUMENTEN-VERTEILUNG.VERTEILUNG.MATRIX.KEINE-ROLLEN';
        field.validationSummary.attributes = new Map<string, string>();
        field.validationSummary.attributes.set('fieldname', field.name);
        return false;
      }
    }
    return true;
  }
}
