import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AlfrescoApiService, CoreModule, TRANSLATION_PROVIDER } from '@alfresco/adf-core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ProcessModule } from '@alfresco/adf-process-services';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentModule, SearchQueryBuilderService } from '@alfresco/adf-content-services';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {
  FLOW_HOME_ROOT,
  FLOW_MY_DOCUMENTS_ROOT,
  FLOW_MY_TASKS_ROOT,
  FLOW_MY_WORKFLOWS_ROOT,
  FLOW_OE_WORKFLOWS_ROOT,
  FLOW_START_WORKFLOW_ROOT,
  FLOW_WORKFLOW_ADMIN_ROOT,
  FlowDataAccessModule,
} from '@alf-nx-workspace/flow/data-access';
import { AuthDataAccessModule } from '@alf-nx-workspace/auth/data-access';
import { PERMISSION_MAPPING, PermissionMapping } from '@alf-nx-workspace/shared/interfaces';
import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxsModule } from '@ngxs/store';
import { FlowAlfrescoApiService } from './services/flow-alfresco-api.service';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { HomeofficeVereinbarungModule } from './workflows/homeoffice-vereinbarung/homeoffice-vereinbarung.module';
import { ReiseAufModule } from './workflows/reise-auf/reise-auf.module';
import { FlowSearchQueryBuilderService } from './services/flow-search-query-builder.service';
import { LanguageComponent } from './language/language.component';
import { FlowRouterStateSerializer } from './services/flow-router-state.serializer';
import { LanguageDataAccessModule } from '@alf-nx-workspace/language/data-access';
import { MasterzulassungModule } from './workflows/interne-masterzulassung/masterzulassung.module';
import { DokumentenFreigabeModule } from './workflows/dokumenten-freigabe/dokumenten-freigabe.module';
import { BaseModule } from './workflows/base.module';
import { DokumentenVerteilungModule } from './workflows/dokumenten-verteilung/dokumenten-verteilung.module';
import { SharedUtilsModule, UnnestingTranslateLoaderService } from '@alf-nx-workspace/shared/utils';
import { EntsorgungAnfordernModule } from './workflows/entsorgung-anfordern/entsorgung-anfordern.module';
import { MasterzulassungEinschreibungModule } from './workflows/masterzulassung-einschreibung/masterzulassung-einschreibung.module';
import { ExampleWorkflowModule } from './workflows/example-workflow/example-workflow.module';
import { FlowErrorHandler } from "./services/flow-error-handler.service";
import { LesebestaetigungAnfordernModule } from './workflows/lesebestaetigung-anfordern/lesebestaetigung-anfordern.module';

registerLocaleData(localeDe);
registerLocaleData(localeEn);

const permissionMapping: PermissionMapping = {
  [FLOW_HOME_ROOT]: [],
  [FLOW_MY_TASKS_ROOT]: [],
  [FLOW_START_WORKFLOW_ROOT]: [],
  [FLOW_MY_WORKFLOWS_ROOT]: [],
  [FLOW_MY_DOCUMENTS_ROOT]: [],
  [FLOW_OE_WORKFLOWS_ROOT]: ['%orgeh.*:process.view-any%'],
  [FLOW_WORKFLOW_ADMIN_ROOT]: ['%workflow.*:process.view-any%'],
};

const providers = [
  { provide: ErrorHandler, useClass: FlowErrorHandler },
  { provide: AlfrescoApiService, useClass: FlowAlfrescoApiService },
  { provide: SearchQueryBuilderService, useClass: FlowSearchQueryBuilderService },
  { provide: PERMISSION_MAPPING, useValue: permissionMapping },
  {
    provide: TRANSLATION_PROVIDER,
    useValue: {
      name: 'boku-flow',
      source: 'assets',
    },
    multi: true,
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: 'DD.MM.YYYY',
      },
      display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    },
  },
  { provide: 'environment', useValue: environment },
  { provide: 'envIsProduction', useValue: environment.production },
  { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  { provide: LOCALE_ID, useValue: 'de-DE' },
  { provide: RouterStateSerializer, useClass: FlowRouterStateSerializer },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    // core
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(APP_ROUTES, { enableTracing: false }),

    // alfresco
    CoreModule.forRoot(),
    ContentModule.forRoot(),

    // translation
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: UnnestingTranslateLoaderService },
    }),

    // state management
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: 'language' }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([]),

    LanguageDataAccessModule,
    AuthDataAccessModule,
    FlowDataAccessModule,

    // top level standalone components
    LanguageComponent,

    // workflow modules
    BaseModule,
    HomeofficeVereinbarungModule,
    ReiseAufModule,
    DokumentenFreigabeModule,
    DokumentenVerteilungModule,
    EntsorgungAnfordernModule,
    MasterzulassungModule,
    MasterzulassungEinschreibungModule,
    ExampleWorkflowModule,
    LesebestaetigungAnfordernModule
  ],

  providers: [...providers],
  bootstrap: [AppComponent],
})
export class AppModule {}
