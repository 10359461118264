import { Injectable, Type } from '@angular/core';
import { EbenProcessInstance, EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { InformationTemplateComponent } from './information-template/information-template.component';
import { ResultTemplateComponent } from './result-template/result-template.component';
import { BaseResultTemplateComponent } from '../base-result-template.component';
import { BaseService } from '../base.service';
import { BaseInformationTemplateComponent } from '../base-information-template.component';

@Injectable({ providedIn: 'root' })
export class LesebestaetigungAnfordernService extends BaseService {
  WORKFLOW_NAME = 'lesebestaetigung-anfordern';
  static WORKFLOW_NAME = 'lesebestaetigung-anfordern';

  isHighPriority(process: EbenProcessInstance): string {
    return "";
  }

  isLongRunning(process: EbenProcessInstance, task: EbenTaskDetail): string {
    return "";
  }

  getInformationTemplate(): Type<BaseInformationTemplateComponent> {
    return InformationTemplateComponent;
  }

  getResultTemplate(): Type<BaseResultTemplateComponent> {
    return ResultTemplateComponent;
  }
}
